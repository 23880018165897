.ftcardbody {
    padding: 0;
}
.ftcardbody .card {
    padding: 15px;
}

.squresub_btn {
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    text-align: right;
}

.forselect_betsmand h5 {
    width: auto;
    display: inline-block;
}

.forselect_betsmand span {
    width: auto;
    float: right;
    cursor: pointer;
    color: #4ba8de;
}

.vjcard_customed .col-1 {max-width: 12%;}
.vjcard_customed input {width: 60px;}



.formore_disisal.col-1 .css-15k3avv {
    position: absolute;
    z-index: 999999999;
}

.formore_disisal {
    max-width: 22% !important;
}

.formore_score {
    max-width: 8% !important;
}

.pointer{
    cursor: pointer;
}

.capitalize{
    text-transform: capitalize; 
}

.submenu{
    margin: 5%;
}

.fontbold{
    font-weight: bold;
}

.mainmenu{
    margin-top: 8px;
}

.chksel{
    padding-top:5px;
}

.chksel input{
    margin-top: 5px;
    margin-left: 4px;
}

li.fullscr.active{
    background-color: #4dacdf;
    color: white;
}

li.fullscr {
    padding: 0px 25px 0px 25px;
}

.teamvs_icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
}

.teamvs_name {
    width: auto;
    display: inline-block;
    vertical-align: middle;
}

span.teamname_dinamic {
    margin-left: 10px;
}

div .teamvs_icon img{
    width: 100%;
}

.match_score{
    margin: 19px;
}

.hidden{
    display:none;
}
.table.wicket_divtd input {
    padding: 0;
    width: 45px;
}

.cui-note.h2 {
    font-size: medium;
    float: right;
    cursor: pointer;
}
.heart-red{
color: #f54545 !important;
}

.social-icons {
width: 120px;
height: 70px;
background: #20a8d8;
text-align: center;
color: #fff;
border-radius: 2%;
padding-top: 15px;
margin-bottom: 20px;
}

.social-icons i {
display: block;

}

.social-icons span {
font-size: 20px;
font-weight: bold;
}
.chart-wrapper {
    height: 300px;
}

/* 4/09 **/
.contest-heart-icon {
    position: absolute;
    right: 0px;
    top: -22PX;
}

span.contest-heart {
    position: relative;
}

/* 09/09 */
ul.nav-dropdown-items {
margin-left: 18px !important;
}


.isRecommended{
    color: #47d147;
}
.private_tabledatamid {
    height: 160px;
    overflow-y: auto;
}
.rankprivate_tablenos table {
    width: 100%;
}
.privatethead_odsinner {width: 100%;}
.privatethead_odsinner input {float: right;}
.privatethead_odsinner label { width: 100%;font-size: 15px;  font-weight: 600;}
.rankprivate_tablenos { border: 1px solid #9b9b9b; box-shadow: 0px 1px 3px 0px #9b9b9b;}
.rankprivate_tablenos { border-radius: 4px; margin: 0 10px 15px; padding: 16px;}
.rankprivate_tablenos.active {  border: 1px solid #45ab06;}

/* The container */
.custom_bcheckad {
    display: block;
    position: relative;
    margin-bottom: 10px;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
.custom_bcheckad input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .custom_bcheckad .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #d6d6d6;
  }
  
  /* On mouse-over, add a grey background color */
.custom_bcheckad :hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
.custom_bcheckad input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .custom_bcheckad .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .custom_bcheckad input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .custom_bcheckad .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    cursor: pointer;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .custom_slab_edit{
  font-size:15px;
  padding: 2px;
  cursor: pointer;
 }


/* loader css*/
.loaderdiv {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.55);
    position: fixed;
    z-index: 99999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.loaderdiv div {
    margin-top: 23%;
    margin-left: 50%;
}



















